// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core from "@linaria/core";
import * as JsxRuntime from "react/jsx-runtime";

import { css, cx } from '@linaria/core'
;

var style = Core.cx("container", "mx-auto", "px-4", "sm:px-6", "lg:px-8");

function Layout$Container(props) {
  var className = props.className;
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: className !== undefined ? cx(style, className) : style
            });
}

var Container = {
  style: style,
  make: Layout$Container
};

export {
  Container ,
}
/*  Not a pure module */
