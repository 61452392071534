// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core from "@lingui/core";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.re.mjs";

var empty = Json_Encode$JsonCombinators.object([]);

var Messages = {
  empty: empty
};

var i18n = Core.i18n;

var I18nProvider = {};

var Util = {};

var UtilString = {};

export {
  Messages ,
  i18n ,
  I18nProvider ,
  Util ,
  UtilString ,
}
/* empty Not a pure module */
