// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Layout from "../Layout.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";

import { t } from '@lingui/macro'
;

function WaitForMessages(props) {
  var children = props.children;
  var query = ReactRouterDom.useLoaderData();
  var loaders = query.i18nLoaders;
  if (loaders !== undefined) {
    return JsxRuntime.jsx(ReactRouterDom.Await, {
                children: (function (param) {
                    return children();
                  }),
                resolve: Caml_option.valFromOption(loaders),
                errorElement: JsxRuntime.jsx(Layout.Container.make, {
                      children: "Error loading translations"
                    })
              });
  } else {
    return children();
  }
}

var make = WaitForMessages;

export {
  make ,
}
/*  Not a pure module */
